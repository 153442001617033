export const GET_CAR_COUNTS = `query getCarCounts($lang: String!, $appId: [Int!] = [])
                               {
                                 carCounts(params: {lang: $lang, appId: $appId}) {
                                   categories {
                                     key
                                     name
                                     docCount
                                   }
                                   manufacturers {
                                     key
                                     name
                                     docCount
                                   }
                                     ev {
                                      key
                                      name
                                      docCount
                                    }
                                    damage {
                                      key
                                      name
                                      docCount
                                    }
                                 }
                               }`
