<template>
  <div class="absolute top-8 z-10 w-[364px] border border-[#e6e6e6] bg-white">
    <SearchCardHeader v-if="data?.count" :count="data?.count" />
    <SearchCardContent
      v-if="data?.carCounts"
      :data="data?.carCounts"
      :counter-button="counterButton"
      :list-url="listUrl"
    />
  </div>
</template>

<script lang="ts" setup>
import { onServerPrefetch } from 'vue'
import type { Button } from '@autobid/ui/types/components/Button'
import { useSearchCardQuery } from '@autobid/ui/composables/useSearchCardQuery'
import SearchCardHeader from './header/SearchCardHeader.vue'
import SearchCardContent from './content/SearchCardContent.vue'

interface Props {
  counterButton: Button
  listUrl: string
}

defineProps<Props>()

const { data, suspense, prefetch } = useSearchCardQuery()

onServerPrefetch(suspense)

if (process.client) {
  await prefetch()
}
</script>
