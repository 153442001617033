<template>
  <div class="full-width-component slider relative z-20">
    <Suspense>
      <SliderInner v-bind="props" />

      <template #fallback>
        <div
          class="slider-main !w-full animate-pulse bg-gray-300"
          :class="height"
        ></div>
      </template>
    </Suspense>

    <div
      v-if="data.showCounter && data.counterButton"
      class="container mx-auto hidden w-screen xl:block"
      data-testid="SearchCard"
    >
      <Suspense>
        <SearchCard
          :counter-button="data.counterButton"
          :list-url="data.listUrl"
        />
        <template #fallback>
          <div
            class="absolute top-8 z-10 h-[350px] w-[350px] animate-pulse bg-gray-200 shadow-md"
          ></div>
        </template>
      </Suspense>
    </div>

    <Suspense v-if="data.showCounter && data.counterButton">
      <SearchCardMobile
        :url="data.counterButton.url"
        :new-tab="data.counterButton.newTab"
      />
      <template #fallback>
        <div
          class="z-10 h-[60px] w-full animate-pulse bg-gray-300 xl:hidden"
        ></div>
      </template>
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import type { Button } from '@autobid/ui/types/components/Button'
import type { Preview } from '@autobid/ui/types/components/Slider'
import SearchCard from '@autobid/ui/components/elements/searchCard/SearchCard.vue'
import SearchCardMobile from '@autobid/ui/components/elements/searchCard/SearchCardMobile.vue'
import type { Locales } from '@autobid/ui/types/Locales'
import SliderInner from './SliderInner.vue'

type Height = 'h300' | 'h420'

type SliderData = {
  autoPlay?: boolean
  playDuration?: number
  collectionName: CollectionName
  autoplayProgress?: boolean
  showCounter?: boolean
  counterButton?: Button
  listUrl?: string
  showThumbs?: boolean
  locales?: Locales
  height?: Height
}

interface Props {
  data?: SliderData
  preview?: Preview
}

const props = defineProps<Props>()

const isCustomHeight = props.data?.height === 'h420'
const height = `${props.data.showThumbs ? 'h-[11.375rem]' : 'h-[9.375rem]'} ${
  isCustomHeight ? ' md:h-[26.25rem]' : 'md:h-[18.75rem]'
}`

provide('height', height)
</script>
