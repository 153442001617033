<template>
  <div
    class="wrapper mt-2 flex w-screen flex-wrap items-center gap-4 p-1 px-2 xl:hidden"
  >
    <SearchCardHeaderCounter
      v-if="data?.count"
      :count="data?.count"
      class="header mx-auto !gap-1.5"
    />
    <nuxt-link
      :href="`/${locale}${url}`"
      class="flex h-full w-full items-center justify-between"
      @click="prefetchDictionary"
      @mouseover="prefetchDictionary"
    >
      <p class="text-xl font-bold uppercase leading-6">
        {{ $t('search-card.vehicles') }}
        online
      </p>
      <Icon name="material-symbols:chevron-right-rounded" class="text-5xl" />
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import { onServerPrefetch } from 'vue'
import { usePrefetchDictionary } from '@autobid/ui/composables/useDictionary'
import { useSearchCardQuery } from '@autobid/ui/composables/useSearchCardQuery'
import SearchCardHeaderCounter from './header/SearchCardHeaderCounter.vue'

interface Props {
  url: string
  newTab: boolean
}

defineProps<Props>()

const { locale } = useI18n()

const prefetchDictionary = usePrefetchDictionary()

const { data, suspense, prefetch } = useSearchCardQuery()
onServerPrefetch(suspense)

if (process.client) {
  await prefetch()
}
</script>

<style lang="scss" scoped>
@media (min-width: 20.5rem) {
  .wrapper {
    flex-wrap: nowrap;
  }

  .header {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
