import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { GET_ALL_CARDS_ID_COUNT } from '../constants/queries/GET_ALL_CARS_ID'
import { GET_CAR_COUNTS } from '../constants/queries/GET_CAR_COUNTS'
import type {
  CarCountData,
  CarCountGroup
} from '../types/components/SearchCard'
import type { SearchCountResponse } from '../types/components/SearchResponse'
import { FIVE_MINUTES_MS } from '../constants/FIVE_MINUTES_MS'
import { useUserApps } from './useUserApps'

type MergedCountData = Record<'categories' | 'manufacturers', CarCountGroup[]>
export const useSearchCardQuery = () => {
  const { userAppIds, useAppsQueryData } = useUserApps()
  const { locale } = useI18n()
  const { $customFetch } = useCustomFetch()

  const queryFn = async () => {
    await useAppsQueryData.refetch()

    const countDataPromise = $customFetch<CarCountData>('/api/backend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        queryApi: 'auctions',
        queryUrl: '/api/v1/count/items',
        variables: {
          lang: locale.value,
          appId: userAppIds.value
        },
        query: GET_CAR_COUNTS
      }
    })

    const totalCountPromise = $customFetch<SearchCountResponse>(
      '/api/backend',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: {
          queryApi: 'auctions',
          queryUrl: '/api/v1/search/items',
          query: GET_ALL_CARDS_ID_COUNT
        }
      }
    )

    const [countDataResult, totalCountResult] = await Promise.allSettled([
      countDataPromise,
      totalCountPromise
    ])

    const handleCountData = (carCountData?: CarCountData) =>
      Object.entries(carCountData?.data?.carCounts || {}).reduce(
        (acc, [key, value]) => {
          sort(acc, key)
          value = filter(value, key)
          injectEvAndDamage(key, acc, value)
          return acc
        },
        {} as MergedCountData
      )
    if (
      countDataResult.status === 'fulfilled' &&
      totalCountResult.status === 'fulfilled'
    ) {
      return {
        carCounts: handleCountData(countDataResult.value),
        count: Number(totalCountResult.value?.data?.carIds[0] ?? 0)
      }
    }
  }
  const queryClient = useQueryClient()

  const prefetch = async () => {
    await queryClient.prefetchQuery({
      queryKey: ['count', locale.value],
      queryFn
    })
  }

  return {
    ...useQuery(['count', locale.value], queryFn, {
      refetchInterval: FIVE_MINUTES_MS,
      staleTime: FIVE_MINUTES_MS,
      cacheTime: FIVE_MINUTES_MS
    }),
    prefetch
  }
}

function sort(acc: MergedCountData, key: string) {
  if (acc[key]) {
    acc[key].sort((a, b) => b.docCount - a.docCount)
  }
}

function injectEvAndDamage(
  key: string,
  acc: MergedCountData,
  value: CarCountGroup[]
) {
  if (key === 'ev' || key === 'damage') {
    if (!acc.categories) {
      acc.categories = []
    }
    const keyMatchGraphqlParam = key === 'ev' ? 'electric' : key
    acc.categories = [
      ...acc.categories.slice(0, 4),
      ...value.map((item) => ({
        ...item,
        key: keyMatchGraphqlParam,
        value: 1
      })),
      ...acc.categories.slice(4)
    ]
  } else {
    acc[key] = value
  }
}

function filter(value: CarCountGroup[], key: string) {
  const maxLen = key === 'categories' ? 4 : 6
  if (value.length > maxLen) {
    const docCount = value
      .slice(maxLen, value.length)
      .reduce((prev, curr) => prev + curr.docCount, 0)
    const key = value.slice(maxLen, value.length).reduce((prev, curr) => {
      prev = prev.length ? `${prev},${curr.key}` : `${curr.key}`
      return prev
    }, '')
    const moreItem = {
      name: 'search-card.more',
      docCount,
      key
    }
    value = [...value.slice(0, maxLen), moreItem]
  }
  return value
}
