<template>
  <div
    class="flex w-full flex-col bg-white p-2 pt-0"
    data-testid="SearchCardContent"
  >
    <div v-if="data" class="grid w-full grid-cols-2 gap-4">
      <SearchCardContentGroup>
        <SearchCardContentTitle>{{
          $t(`search-card.manufacturers`)
        }}</SearchCardContentTitle>
        <SearchCardContentGroupItems>
          <SearchCardContentItem
            v-for="(item, index) of data.manufacturers"
            :key="item.name"
            :value="item.key"
            :name="item.name"
            :href="listUrl"
            :count="item.docCount"
            type="brandId-brand"
            :class="
              index === data.manufacturers.length - 1 ? '!border-none' : ''
            "
          />
        </SearchCardContentGroupItems>
      </SearchCardContentGroup>
      <SearchCardContentGroup>
        <SearchCardContentTitle>{{
          $t(`search-card.categories`)
        }}</SearchCardContentTitle>
        <SearchCardContentGroupItems>
          <SearchCardContentItem
            v-for="(item, index) of data.categories"
            :key="item.name"
            :value="getItemValue(item.key)"
            :name="item.name"
            :href="listUrl"
            :count="item.docCount"
            :type="getCategoryKey(item.key)"
            :class="index === data.categories.length - 1 ? '!border-none' : ''"
          />
        </SearchCardContentGroupItems>
      </SearchCardContentGroup>
    </div>
    <div class="mt-4 w-full bg-white">
      <elements-button
        class="block !w-full !p-1 !text-sm"
        v-bind="_counterButton._counterButtonProps"
        :url="`/${locale}${_counterButton.url}`"
      >
        {{ counterButton.text }}
      </elements-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { useSearchCardQuery } from '@autobid/ui/composables/useSearchCardQuery'
import type { Button } from '@autobid/ui/types/components/Button'
import SearchCardContentTitle from './SearchCardContentTitle.vue'
import SearchCardContentGroup from './SearchCardContentGroup.vue'
import SearchCardContentGroupItems from './SearchCardContentGroupItems.vue'
import SearchCardContentItem from './SearchCardContentItem.vue'

const { locale } = useI18n()

const getCategoryKey = (key: string) => {
  switch (key) {
    case 'electric':
      return 'e17'
    case 'damage':
      return 'damage'
    default: {
      return 'categoryId-category'
    }
  }
}

const getItemValue = (key: string) => {
  switch (key) {
    case 'electric':
      return '6,7,8,12,13,14,15,16,17'
    case 'damage':
      return 1
    default: {
      return key
    }
  }
}

interface Props {
  data: ReturnType<typeof useSearchCardQuery>['data']['value']['carCounts']
  counterButton?: Button
  listUrl?: string
}

const props = defineProps<Props>()

const _counterButton = computed(() => {
  const { url, ..._counterButtonProps } = props.counterButton

  return {
    url,
    _counterButtonProps
  }
})
</script>
